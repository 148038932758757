import React, { useState } from 'react';
import { Link } from 'gatsby';

import BlogArrow from '@assets/blog/svg-icons/blog-arrow';

export default function ArrowButton({ linkUrl, buttonText }) {
  const [colorOfArrow, setColorOfArrow] = useState(true);
  return (
    <div className="landing-view-all">
      <Link to={linkUrl}>
        <div
          className="landgin-view-all-text"
          onMouseOver={() => {
            setTimeout(() => {
              setColorOfArrow(false);
            }, 300);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setColorOfArrow(true);
            }, 0);

            setTimeout(() => {
              setColorOfArrow(true);
            }, 301);
          }}
        >
          {buttonText}
        </div>
      </Link>

      <span className={colorOfArrow ? 'view-all-arrow' : 'view-all-arrow slide-right'}>
        <span className="orange-arrow">
          <BlogArrow colorFill={colorOfArrow ? '#f15e32' : '#000000'} />
        </span>
      </span>
    </div>
  );
}
