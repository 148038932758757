import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';

import Grid from '@assets/blog-category/grid';
import Sidebar from '@assets/blog-category/sidebar';
import BookOpen from '@assets/blog-category/book-open';
import Text from '@assets/blog-category/text';
import Dot from '@assets/blog-category/dot';
import BlogLine from './blog-horizontal-line';
import SearchContext from '../../context/search-context';
import SecondaryButton from '../reusable-buttons/secondary-button';
import LanguageContext from '../../context/language-context';

const BlogCategory = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allPrismicBlogCategories {
        nodes {
          data {
            category {
              text
            }
          }
        }
      }
      allPrismicBlogDetails {
        nodes {
          uid
          data {
            featuredImage: featured_image {
              fluid {
                src
              }
            }
            tags {
              text
            }
            title {
              text
            }
            language
            excerpt {
              text
            }
            blogCategories: blog_categories {
              category {
                document {
                  ... on PrismicBlogCategories {
                    id
                    data {
                      category {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicRecipes {
        nodes {
          data {
            excerpt {
              text
            }
            featuredImage: featured_image {
              fluid {
                src
              }
            }
            postedOn: posted_on(fromNow: true)
            tags {
              text
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  `);

  const contentResources = {
    eng: {
      titleFirst: 'Browse by category ',
      titleSecond: 'researchers & authors',
      all: 'All',
      recipes: 'Recipes',
      fromBooks: 'From the books',
      articles: 'Articles',
      blogs: 'Blogs',
      viewallButton: 'View All',
    },
    esp: {
      titleFirst: 'Buscar por categoría',
      titleSecond: 'editores',
      all: 'Todo',
      recipes: 'Recetas',
      articles: 'Artículos',
      blogs: 'Blogs',
      fromBooks: 'De los libros',
      viewallButton: 'Ver todo',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  const { searchResult, setSearchResult } = useContext(SearchContext);
  const { contentLanguage } = useContext(LanguageContext);

  const SpanishblogsData = data.allPrismicBlogDetails.nodes.filter((item) => item.data.language === 'Spanish');
  const EngblogsData = data.allPrismicBlogDetails.nodes.filter((item) => item.data.language === 'English');
  const [blogsData, setBlogsData] = useState(contentLanguage === 'eng' ? EngblogsData : SpanishblogsData);
  const [lessBlogData, setlessBlogData] = useState(blogsData.filter((item, idx) => idx < 6));
  const category = data.allPrismicBlogCategories.nodes.map((item) => item.data.category.text);

  //const blogsData = data.allPrismicBlogDetails.nodes;
  // const lessBlogData = blogsData.filter((item, idx) => idx < 6);

  const recepiesOnly = data.allPrismicRecipes.nodes;
  const lessReCipes = recepiesOnly.filter((item, idx) => idx < 6);

  const articlesOrFromBooks = data.allPrismicBlogDetails.nodes.filter((item) => {
    const category1 = item.data.blogCategories[0];
    const category2 = item.data.blogCategories[1];
    return (
      category1?.category.document?.data?.category.text === 'From the books' ||
      category1?.category.document?.data?.category.text === 'Articles' ||
      category2?.category.document?.data?.category.text === 'From the books' ||
      category2?.category.document?.data?.category.text === 'Articles'
    );
  });

  const lessArticlesOrFromBooks = articlesOrFromBooks.filter((item, idx) => idx < 6);

  const blogsOnly = data.allPrismicBlogDetails.nodes.filter((item) => {
    const category1 = item.data.blogCategories[0];

    return category1?.category.document?.data?.category.text === 'Blogs';
  });

  const lessBlogsOnly = blogsOnly.filter((item, idx) => idx < 6);
  const articlesOnly = data.allPrismicBlogDetails.nodes.filter((item) => {
    const category1 = item.data.blogCategories[0];
    const category2 = item.data.blogCategories[1];
    return (
      category1?.category.document?.data?.category.text === 'Articles' ||
      category2?.category.document?.data?.category.text === 'Articles'
    );
  });

  const lessArticles = articlesOnly.filter((item, idx) => idx < 6);

  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showLoadLess, setShowLoadLess] = useState(false);
  const [blogCategories, setBlogCategories] = useState(lessBlogData);

  const [Featured, Blogs, Articles, Recepies, FromTheBooks] = category;

  const [deviceIsTab, setDeviceStatus] = useState(false);
  const [activeClass, setActiveClass] = useState({
    all: true,
    recipe: false,
    books: false,
    articles: false,
    blogs: false,
  });
  useEffect(() => {
    if (contentLanguage === 'eng') {
      setCurrentContent(contentResources.eng);
      if (activeClass.all) {
        setBlogsData(EngblogsData);
        setBlogCategories(EngblogsData.filter((item) => item.data.language === 'English').filter((item, i) => i < 6));
        setlessBlogData(EngblogsData.filter((item, idx) => idx < 6));
      } else if (activeClass.blogs) {
        setBlogCategories(blogsOnly.filter((item) => item.data.language === 'English'));
      }
    } else if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
      if (activeClass.all) {
        setBlogsData(SpanishblogsData);
        setBlogCategories(
          SpanishblogsData.filter((item) => item.data.language === 'Spanish').filter((item, i) => i < 6)
        );
        setlessBlogData(SpanishblogsData.filter((item, idx) => idx < 6));
      } else if (activeClass.blogs) {
        setBlogCategories(blogsOnly.filter((item) => item.data.language === 'Spanish'));
      }
    }
  }, [contentLanguage]);

  useEffect(() => {
    let deviceWidth = window.matchMedia('(max-width: 1024px)');

    setDeviceStatus(deviceWidth.matches);
  }, [deviceIsTab]);

  return (
    <div className="common-container">
      <div className="blog-category-holder">
        <div className="blog-category-title">
          {!searchResult.search ? (
            <h4>{currentContent.titleFirst}</h4>
          ) : (
            <h2>
              {contentLanguage === 'eng'
                ? ' Here is the serch results for'
                : 'Aquí están los resultados de búsqueda para'}
              <h2>{searchResult.query}</h2>
            </h2>
          )}

          {searchResult.result.length === 0 && searchResult.search ? (
            <h2 className="h2-modified">{searchResult.sorryMessage}</h2>
          ) : (
            ''
          )}
        </div>
        {!searchResult.search ? (
          <div className="blog-category-option-holder">
            <div
              className={activeClass.all ? 'blog-category-options active-options' : 'blog-category-options'}
              onClick={() => {
                setShowLoadLess(false);
                setShowLoadMore(true);
                setBlogCategories(lessBlogData);
                setActiveClass(() => ({
                  ...false,
                  all: true,
                }));
              }}
            >
              <Grid color="#000000" />
              <div className="options-text-holder">
                <p className="button-text">{currentContent.all}</p>
              </div>
            </div>
            <div
              className={activeClass.articles ? 'blog-category-options active-options' : 'blog-category-options'}
              onClick={() => {
                setShowLoadLess(false);
                setShowLoadMore(true);
                setBlogCategories(lessArticles);
                setActiveClass(() => ({
                  ...false,
                  articles: true,
                }));
              }}
            >
              <Text />
              <div className="options-text-holder">
                <p className="button-text">{currentContent.articles}</p>
              </div>
            </div>
            <div
              className={activeClass.blogs ? 'blog-category-options active-options' : 'blog-category-options'}
              onClick={() => {
                setShowLoadLess(false);
                setShowLoadMore(true);
                setBlogCategories(lessBlogsOnly);
                setActiveClass(() => ({
                  ...false,
                  blogs: true,
                }));
              }}
            >
              <Dot />
              <div className="options-text-holder">
                <p className="button-text">{currentContent.blogs}</p>
              </div>
            </div>
            <div
              className={activeClass.recipe ? 'blog-category-options active-options' : 'blog-category-options'}
              onClick={() => {
                setShowLoadLess(false);
                setShowLoadMore(true);
                setBlogCategories(lessReCipes);
                setActiveClass(() => ({
                  ...false,
                  recipe: true,
                }));
              }}
            >
              <Sidebar />
              <div className="options-text-holder">
                <p className="button-text">{currentContent.recipes}</p>
              </div>
            </div>
            <div
              className={activeClass.books ? 'blog-category-options active-options' : 'blog-category-options'}
              onClick={() => {
                setShowLoadLess(false);
                setShowLoadMore(true);
                setBlogCategories(lessArticlesOrFromBooks);
                setActiveClass(() => ({
                  ...false,
                  books: true,
                }));
              }}
            >
              <BookOpen />
              <div className="options-text-holder">
                <p className="button-text">{currentContent.fromBooks}</p>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="blog-category-blogs-container">
          {(searchResult.search ? searchResult.result : blogCategories).map((item, idx) => {
            const { title, tags, excerpt, featuredImage } = item.data;
            return (
              <div
                className="blog-category-blogs"
                key={idx}
                className={
                  deviceIsTab
                    ? idx % 2 === 0
                      ? 'no-margin blog-category-blogs'
                      : 'blog-category-blogs'
                    : idx % 3 === 0
                    ? 'no-margin blog-category-blogs'
                    : 'blog-category-blogs'
                }
              >
                <Link to={item.uid}>
                  <div className="blog-category-photo">
                    <img src={featuredImage.fluid?.src} alt="blog" />
                  </div>
                </Link>

                <div className="blog-category-photo-title">
                  <h4>{tags?.text}</h4>
                </div>
                <div className="blog-category-photo-heading">
                  <h4>{title?.text}</h4>
                </div>
                <div className="blog-category-photo-description">
                  <h6>{excerpt?.text}</h6>
                </div>
                <div className="blog-category-photo-readmore">
                  <Link to={item.uid}>
                    <h6>{contentLanguage === 'eng' ? 'Read more' : 'Lee mas'}</h6>
                  </Link>
                </div>
                {deviceIsTab ? (
                  idx % 2 === 0 && idx != 0 ? (
                    <BlogLine />
                  ) : null
                ) : idx % 3 === 0 && idx != 0 ? (
                  <BlogLine />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <div className={!searchResult.search ? 'blog-category-load-button-container' : 'hide'}>
        <div
          className={showLoadLess ? 'show blog-category-load-more-less-holder' : 'hide'}
          onClick={() => {
            setShowLoadMore(!showLoadMore);
            setShowLoadLess(!showLoadLess);
            if (activeClass.all) {
              setBlogCategories(lessBlogData);
            } else if (activeClass.recipe) {
              setBlogCategories(lessReCipes);
            } else if (activeClass.books) {
              setBlogCategories(lessArticlesOrFromBooks);
            } else if (activeClass.articles) {
              setBlogCategories(lessArticles);
            } else {
              setBlogCategories(blogsOnly);
            }
          }}
        >
          <SecondaryButton buttonText={contentLanguage === 'eng' ? 'Show Less' : 'Muestra menos'} />
        </div>
        <div
          className={showLoadMore ? 'show blog-category-load-more-less-holder' : 'hide'}
          onClick={() => {
            setShowLoadMore(!showLoadMore);
            setShowLoadLess(!showLoadLess);
            if (activeClass.all) {
              setBlogCategories(blogsData);
            } else if (activeClass.recipe) {
              setBlogCategories(recepiesOnly);
            } else if (activeClass.books) {
              setBlogCategories(articlesOrFromBooks);
            } else if (activeClass.articles) {
              setBlogCategories(articlesOnly);
            } else {
              setBlogCategories(blogsOnly);
            }
          }}
        >
          <SecondaryButton buttonText={contentLanguage === 'eng' ? 'Load More' : 'Carga más'} />
        </div>
      </div>
    </div>
  );
};

export default BlogCategory;
