import React from 'react';

export default function BlogArrow({ colorFill }) {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.2719 1L6.90234 7L1.2719 13"
        stroke={colorFill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
