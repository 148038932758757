import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';

import SearchIcon from '@assets/blog/svg-icons/search-icon';

export default function SearchField({ searchResult, setSearchResult }) {
  const data = useStaticQuery(graphql`
    query MySearchQuery {
      allPrismicBlogDetails {
        nodes {
          uid
          data {
            featuredImage: featured_image {
              fluid {
                src
              }
            }
            tags {
              text
            }
            title {
              text
            }
            excerpt {
              text
            }
            blogCategories: blog_categories {
              category {
                document {
                  ... on PrismicBlogCategories {
                    id
                    data {
                      category {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicRecipes {
        nodes {
          data {
            excerpt {
              text
            }
            featuredImage: featured_image {
              fluid {
                src
              }
            }
            postedOn: posted_on(fromNow: true)
            tags {
              text
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  `);

  const blogsData = data.allPrismicBlogDetails.nodes;
  const recepiesOnly = data.allPrismicRecipes.nodes;
  const allData = blogsData.concat(recepiesOnly);
  const [recommendedItemToSearch, setRecommendedItemToSearch] = useState([]);

  const recommendedSearch = allData.map((item) => item.uid);

  const [inputData, setInputData] = useState('');

  const handleChange = (event) => {
    let dataToSearch = event.target.value;
    if (dataToSearch === '') {
      setSearchResult({
        ...searchResult,
        recommendedSearchItem: [],
      });
    } else {
      const filteredItemToSearch = recommendedSearch.filter((item) => item.includes(dataToSearch));

      setRecommendedItemToSearch(filteredItemToSearch);
      setSearchResult({
        ...searchResult,
        recommendedSearchItem: filteredItemToSearch,
      });
    }

    setInputData(dataToSearch);
  };

  const searchAllData = (itemToSearch, from) => {
    const searchData = allData.filter((item) => item.uid.includes(itemToSearch));

    if (from === 'icon') {
      if (inputData === '') {
        return;
      }
      setSearchResult({
        ...searchResult,
        query: `"${inputData}"`,
        search: true,
        result: searchData,
        recommendedSearchItem: [],
      });
    } else {
      setSearchResult({
        ...searchResult,
        query: `"${itemToSearch}"`,
        search: true,
        result: searchData,
        recommendedSearchItem: [],
      });
    }
  };

  return (
    <div className="blog-search-field">
      <div className="text-input-field">
        <input
          className="blog-input-search"
          autoComplete="off"
          type="text"
          name="blogs"
          value={inputData}
          id="searchData"
          placeholder="Search "
          onChange={handleChange}
        />
      </div>
      <div
        className="search-icon"
        onClick={() => {
          searchAllData(inputData, 'icon');
        }}
      >
        <SearchIcon />
      </div>
      <div className="blog-search-recommended-field">
        {searchResult.recommendedSearchItem.map((item) => {
          return (
            <div className="blog-search-recommended-item" onClick={() => searchAllData(item, 'select')}>
              <h6>{item}</h6>
            </div>
          );
        })}
      </div>
    </div>
  );
}
