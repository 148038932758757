import React, { useEffect, useContext, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import ArrowButton from '../reusable-buttons/arrow-button';
import LanguageContext from '../../context/language-context';

export default function FeaturedPost() {
  const data = useStaticQuery(graphql`
    query featured {
      allPrismicBlogDetails(
        filter: { data: { blog_categories: { elemMatch: { category: { slug: { eq: "featured" } } } } } }
      ) {
        edges {
          node {
            id
            uid
            data {
              featured_image {
                fluid {
                  src
                }
              }
              title {
                text
              }
              language
              excerpt {
                text
              }
              tags {
                text
              }
            }
          }
        }
      }
    }
  `);

  const { contentLanguage } = useContext(LanguageContext);

  const engFeaturedPost = data.allPrismicBlogDetails.edges.filter((item) => item.node.data.language === 'English');
  const espFeaturedPost = data.allPrismicBlogDetails.edges.filter((item) => item.node.data.language === 'Spanish');

  const [featuredPostData, setFeaturedPost] = useState(data.allPrismicBlogDetails.edges[0].node.data);
  useEffect(() => {
    if (contentLanguage === 'eng') {
      setFeaturedPost(engFeaturedPost[0]?.node?.data);
    } else if (contentLanguage === 'esp') {
      //to be changed
      setFeaturedPost(espFeaturedPost[0]?.node?.data || engFeaturedPost[0]?.node?.data);
    }
  }, [contentLanguage]);

  const { title, excerpt, featured_image, tags } = featuredPostData;

  return (
    <div className="common-container">
      <div className="featured-post-holder">
        <div className="featured-post-title">
          <h2>{contentLanguage === 'eng' ? 'Featured post' : 'Publicación destacada'}</h2>
        </div>
        <div className="featured-post-content">
          <div className="post-image">
            <Link to={`/blog/${data.allPrismicBlogDetails.edges[0].node.uid}`}>
              <img src={featured_image?.fluid.src} alt="post-banner" />
            </Link>
          </div>
          <div className="post-content">
            <div className="post-title-text">
              <div className="subtitle-text">{tags?.text}</div>
            </div>
            <div className="post-introduction">
              <h3 className="post-intro-text">{title?.text}</h3>
            </div>
            <div className="post-description">
              <>{excerpt?.text}</>
            </div>
            <ArrowButton
              buttonText={contentLanguage === 'eng' ? 'Read More' : 'Lee mas'}
              linkUrl={`/blog/${data.allPrismicBlogDetails.edges[0].node.uid}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
