import React, { useState } from 'react';

import Layouts from '@layouts';
import Seo from '../../layouts/common/seo';
import BLogIntro from '@components/blog/blog-intro';
import seoImage from '@assets/blog/images/heroBG.png';
import SearchContext from '../../context/search-context';
import BlogCategory from '@components/blog/blog-category';
import FeaturedPost from '@components/blog/featured-post';

const BlogPage = () => {
  const [searchResult, setSearchResult] = useState({
    recommendedSearchItem: [],
    query: '',
    search: false,
    result: [],
    sorryMessage: 'Sorry!No search result found.',
  });
  return (
    <>
      <Seo
        title="Best Blogs on Healthy Living | Better Way of Life."
        description="Discover Healthier Ways of Living by Reading Our Blogs."
        seoImage={seoImage}
        siteUrl={process.env.CLIENT_URL + 'blog'}
      />
      <SearchContext.Provider value={{ searchResult, setSearchResult }}>
        <Layouts>
          <BLogIntro />
          {!searchResult.search ? <FeaturedPost /> : ''}

          <BlogCategory />
        </Layouts>
      </SearchContext.Provider>
    </>
  );
};

export default BlogPage;
