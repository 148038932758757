import React, { useContext, useEffect, useState } from 'react';

import SearchField from './search-field';
import SearchContext from '../../context/search-context';
import LanguageContext from '../../context/language-context';

export default function BLogIntro() {
  const { searchResult, setSearchResult } = useContext(SearchContext);
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      heroText: 'Are you tired of not knowing how to achieve a healthy lifestyle?',
      heroSubText: 'We’re here to show you the way! Get the basics on healthy living from our blog.',
    },
    esp: {
      heroText: '¿Estás cansado de no saber cómo lograr un estilo de vida saludable?',
      heroSubText:
        '¡Estamos aquí para mostrarte el camino! Obtenga los conceptos básicos sobre una vida saludable en nuestro blog.',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);

  return (
    <div className="outermost-blog-intro-holder u-margin-top-lg">
      <div className="gradient-bg "> </div>
      <div className="blog-intro-holder ">
        <h3>{currentContent?.heroText} </h3>
        <h6>{currentContent?.heroSubText}</h6>
      </div>

      <div className="search-field">
        <SearchField searchResult={searchResult} setSearchResult={setSearchResult} />
      </div>
    </div>
  );
}
